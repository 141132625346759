import React, { useState } from 'react'
import * as pdfjs from "pdfjs-dist"
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
import * as extractor from './extract'
import { FileUploader } from "react-drag-drop-files"
import {NotificationContainer, NotificationManager} from 'react-notifications'
import { v4 as uuidv4 } from 'uuid';
import './Datafinder.scss'

const fileTypes = ["PDF"];
const fileTypesDatabase = ["JSON"];
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

var canSelectDays=false;
var canSelectHours=false;
var Days={start:null,end:null}
var Hours={start:null,end:null};
function Datafinder() {

var isContextMenuShown=false
var currentEvent=null
var currentItem=null
var currentPage=1

const [context, setContext] = useState(null);
const [pdfScreenWidth, setPdfScreenWidth] = useState(900);
const [pdfScreenHeight, setPdfScreenHeight] = useState(600);
const [firstBackupImageData, setFirstBackupImageData] = useState(null);
const [pdfScreenScale, setPdfScreenScale] = useState(1.5);
const [pageBrowser, setPageBrowser] = useState({display:'none'});
const [database, setDatabase] = useState([]);
const [contextMenu, setContextMenu] = useState();
const [pdfItems, setPDFItems] = useState(null);
const [pdfPage, setPDFPage] = useState(1);
const [pdfFile, setPDFFile] = useState();
const [rangeYforExtraction, setRangeYFE] = useState(extractor.rangeYforExtraction);
const [persRange, setPersRange] = useState(false);
const [selectedFile, setSelectedFile] = useState();
const [isFileSelected, setIsSelected] = useState(false);
const [cambioOrientamento, setCambioOrientamento] = useState(false);
const [pagine, SetPagine] = useState([]);
const [loading,setLoading] = useState(false)
const [canSelectItem,setCanSelectItem] = useState(true)
const [percLoad,setPercLoad] = useState("0%")
const [databases,setDatabases] = useState([])

React.useEffect(()=>{
  if(cambioOrientamento){    
    resetCTX();
    openFile();
  }
})

var readPDFfromFile=(file)=>{
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>{
    setSelectedFile(reader.result)
    setIsSelected(true)
  }
}
var readJson=(file)=>{
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>{
      fetch(reader.result).then(response=>{
          response.json().then(json=>{
              setDatabase(json)
          })
      })
  }
}



var refreshContextMenu=(e)=>{
  if((currentEvent!==null)||isContextMenuShown===true)
  {
    isContextMenuShown=true
    var dialogMenuCSS={
    left:e.clientX-200,
    top:e.clientY
    }
    var itemContent=""
    if(currentItem!==null)
    {
      itemContent=(
        <>
          <button onClick={()=>{extractor.SwitchCanShowSchemaList();refreshContextMenu(e);}}>Imposta Parametro</button>

        </>
      )
    }
    setContextMenu(
      <div className='dialogMenu' style={dialogMenuCSS}>
        <div className='header-topcontext'><span>Opzioni</span><span onClick={hideContextMenu} >&times;</span></div>
        <div>
          {itemContent}  
          <button onClick={()=>{hideContextMenu(); canSelectDays=true}}>Seleziona Giorni</button>          
          <button onClick={()=>{hideContextMenu(); canSelectHours=true}}>Seleziona Ore</button>          
          <button onClick={()=>{resetCampi(); canSelectHours=false; canSelectHours=false;}}>Pulisci Campi</button>          
        </div>
        {extractor.sList(currentItem,()=>{setUpPagine("element"); hideContextMenu()})}
      </div>
      )
      
  }else{hideContextMenu() }
}

var openContextMenu=(item,e)=>{
  e.preventDefault()  
  //console.log(canSelectItem)
  if(canSelectItem===true){
    if(item!==null)
    currentItem={page:currentPage,item:item}
    currentEvent=e
    refreshContextMenu(e)
  }
}
var setUpPagine=(action)=>{
  if(verifica().length>0)
  {
    SetPagine(prev=>{
      if(prev.filter(e => e.page === currentPage && e.action === action).length > 0)
        return prev
      else
        return [...prev,{page:currentPage,action:action}]
    })

  }
}
var hideContextMenu=()=>{
  isContextMenuShown=false
  currentItem=null
  currentEvent=null
  if(extractor.canshowSchemaList==='block')
  extractor.SwitchCanShowSchemaList()
  setContextMenu("");
  
}

var cambiaOrientamento=()=>{
  if(isFileSelected){
    const width=parseInt(pdfScreenWidth.toString())
    const height=parseInt(pdfScreenHeight.toString())
    setPdfScreenHeight(width)
    setPdfScreenWidth(height)

    setCambioOrientamento(true)
  }
}

var renderPage=(page)=>{
  
  var isDragging=false
  var startX=null;
  var startY=null;
  var backupImageData=null;
  
  const viewport = page.getViewport(pdfScreenScale);
        viewport.rotation=90
        viewport.scale=pdfScreenScale;
        viewport.width=pdfScreenWidth*pdfScreenScale;
        viewport.height=pdfScreenHeight*pdfScreenScale;
        viewport.transform = [pdfScreenScale, 0, 0, -pdfScreenScale, 0, pdfScreenWidth*pdfScreenScale]    

  const canvas = document.getElementById('pdf');
        canvas.height = viewport.width;
        canvas.width = viewport.height;  
                
        
        
  const ctx = context!==null?context:canvas.getContext('2d')
        ctx.strokeStyle = "#FF0000"
        ctx.lineWidth = 1
  setContext(ctx)

  const renderContext = {
        canvasContext: ctx,
        viewport: viewport
        };   
        page.render(renderContext)
        setTimeout(()=>{
          backupImageData=ctx.getImageData(0,0,canvas.width,canvas.height);
          setFirstBackupImageData(backupImageData);
        canvas.onmousedown=(e)=>{
          e.preventDefault()
          if(e.button!==0) return
          isDragging=true
            let rect = canvas.getBoundingClientRect()
            let x = e.clientX - rect.left
            let y = e.clientY - rect.top
            startX=x;
            startY=y;

            
            if(canSelectDays)
            {
              setCanSelectItem(false)
              Days.start={x:x/pdfScreenScale,y:y/-pdfScreenScale+pdfScreenWidth}
            }
            else if(canSelectHours)
            {
              setCanSelectItem(false)
              Hours.start={x:x/pdfScreenScale,y:y/-pdfScreenScale+pdfScreenWidth}
            }
            ctx.save()
        }
        canvas.onmouseup=(e)=>{
          if(isDragging===true&&e.button===0)
          {
            isDragging=false
            let rect = canvas.getBoundingClientRect()
            let x = e.clientX - rect.left
            let y = e.clientY - rect.top

            if(canSelectDays)
            {
              Days.end={x:x/pdfScreenScale,y:y/-pdfScreenScale+pdfScreenWidth}
              const days={sx:Days.start.x,ex:Days.end.x,sy:Days.start.y,ey:Days.end.y}
              Days.start.x=days.sx<days.ex?days.sx:days.ex
              Days.end.x=days.sx>days.ex?days.sx:days.ex
              Days.start.y=days.sy<days.ey?days.sy:days.ey
              Days.end.y=days.sy>days.ey?days.sy:days.ey
              setUpPagine("days")
            }
            else if(canSelectHours)
            {
              Hours.end={x:x/pdfScreenScale,y:y/-pdfScreenScale+pdfScreenWidth}
              const hours={sx:Hours.start.x,ex:Hours.end.x,sy:Hours.start.y,ey:Hours.end.y}
              Hours.start.x=hours.sx<hours.ex?hours.sx:hours.ex
              Hours.end.x=hours.sx>hours.ex?hours.sx:hours.ex
              Hours.start.y=hours.sy<hours.ey?hours.sy:hours.ey
              Hours.end.y=hours.sy>hours.ey?hours.sy:hours.ey
              setUpPagine("days")
            }
            backupImageData=ctx.getImageData(0,0,canvas.width,canvas.height);
            startX=null
            startY=null
            canSelectDays=false
            canSelectHours=false
            setCanSelectItem(true)
          }
        }
        canvas.onmousemove=(e)=>{
          if(isDragging&&(canSelectDays===true||canSelectHours===true)&&e.button===0)
          {      
            let rect = canvas.getBoundingClientRect()
            let x = e.clientX - rect.left
            let y = e.clientY - rect.top
            const r = {
              x: startX,
              y: startY,
              w: x - startX,
              h: y - startY,
            }
            ctx.clearRect(0,0,canvas.width,canvas.height)  
            ctx.putImageData(backupImageData,0,0)
            ctx.strokeRect(r.x, r.y, r.w, r.h)    
          }
        }
      },500);
}
var resetCTX=()=>{
  if(firstBackupImageData!==null){
    const canvas=document.getElementById('pdf')
    const ctx=context!==null?context:canvas.getContext('2d')
    ctx.clearRect(0,0,canvas.width,canvas.height)
    ctx.putImageData(firstBackupImageData,0,0)
  }
}
var resetCampi=()=>{
  resetCTX()
  extractor.schema.nome=null
  extractor.schema.cognome=null
  extractor.schema.codicefiscale=null
  extractor.schema.anno=null
  extractor.schema.mese=null
  extractor.schema.mansione=null
  SetPagine([])
  /*extractor.schema.totaleOreDC=null
  extractor.schema.datainizioL=null
  extractor.schema.datafineL=null*/
  
  hideContextMenu()
}

var openFile=()=>{
  if(isFileSelected){
    const pdf = pdfjs.getDocument(selectedFile)
    var str=[];
    pdf.promise.then(function(pdf){
      setPDFFile(pdf)
      var pdf_canvas=[]; 
      pdf.getPage(currentPage).then(function(page) {
        renderPage(page)
        page.getTextContent().then(function(textContent) {
          for(let j = 0; j < textContent.items.length; j++) {
            const item=textContent.items[j];
            if(item.str.trim()!==null&&item.str.trim()!=='')
            {       
              const itemStyle={position:'absolute',
              zIndex:10,
              top:(item.transform[5]*-pdfScreenScale+pdfScreenWidth*pdfScreenScale)-(item.transform[0]>0?item.height:item.width)*pdfScreenScale,
              left:item.transform[4]*pdfScreenScale-(item.transform[0]>0?0:4*pdfScreenScale),
              width:(item.transform[0]>0?item.width:item.height)*pdfScreenScale,
              height:(item.transform[0]>0?item.height+1*pdfScreenScale:item.width)*pdfScreenScale,
              //userSelect:(canSelectDays||canSelectHours)?'none':'inherit',
              //userSelect:'none',
              display:canSelectItem?'block':'none'}       
              pdf_canvas.push(<div className='strings' /*onClick={()=>{console.log(item)}}*/ onClick={openContextMenu.bind(this, item)} style={itemStyle}></div>)
              str.push(item);
            }
          }
          setPDFItems(pdf_canvas)  
        });
      });
    })
  }else NotificationManager.warning('Nessun file selezionato')
  
  setCambioOrientamento(false)
}
const getDatabaseIndex=(codicefiscale,database)=>{
  var r=-1
  try{
  for(var i=0;i<database.length;i++){
    if(database[i].codicefiscale.toUpperCase().trim()===codicefiscale.toUpperCase().trim())
    r=i
  }}catch{
    
  }
  return r
}

var verifica=()=>{

  var errori=[]

  if(!isFileSelected)
    errori.push('Nessun file selezionato')
  
  if(extractor.schema.nome===null||extractor.schema.cognome===null)
  errori.push('Devi selezionare il Nome ed il Cognome')
  if(extractor.schema.codicefiscale===null)
  errori.push('Devi selezionare il Codice Fiscale')
  if(extractor.schema.mese===null)
  errori.push('Devi selezionare il mese')
  if(extractor.schema.mansione===null)
  errori.push('Devi selezionare la mansione')
  if(extractor.schema.anno===null)
  errori.push('Devi selezionare l\'anno')
  if(Days.start===null||Days.end===null)
  errori.push('Devi selezionare i giorni')
  if(Hours.start===null||Hours.end===null)
  errori.push('Devi selezionare le ore')
  
  return errori
}
const lastCF={cf:null}
var elabora_buste_paghe=(e)=>{
  e.preventDefault()
  const elementPage=pagine.find(v=>v.action==="element").page
  const daysPage=pagine.find(v=>v.action==="days").page
  var errori=verifica()
  
  if(errori.length>0)
  {
    errori.forEach(str=>{
      NotificationManager.warning(str)
    })
    return
  }

  setLoading(true)
  var str=JSON.parse(JSON.stringify(database))
  //console.log(str)
  //extractor.setRangeFE(event.target.value)
  for(var r=1;r<=5;r++){
    if((persRange&&r===1)||(!persRange&&r<=5))
  for(var i=1;i<=pdfFile.numPages;i++)
  {  
    if(!persRange)
    extractor.setRangeFE(r) 
    setTimeout((i,r)=>{
    //console.log(lastCF)c
    const elementToTake=((elementPage>1&&i % elementPage === 0) || (elementPage===1&&i % 2 !== 0))
    const daysToTake=((daysPage>1&&i % daysPage === 0) || (daysPage===1&&i % 2 !== 0))
    //console.log(elementToTake,daysToTake)
    const needNewSchema=i%pagine.length;
    const newSchema=JSON.parse(JSON.stringify(extractor.schemaEmpty))
    const elementExists={index:-1}
    const schema = (elementPage===daysPage)?newSchema:(needNewSchema?newSchema:(str.length>0&&lastCF.cf!==null?str.find(el=>el.codicefiscale === lastCF.cf):newSchema))
    if((daysToTake&&!elementToTake)&&schema?.codicefiscale!==null)
    {
      var index=getDatabaseIndex(schema.codicefiscale,str)
      if(index>=0)
      {
        elementExists.index=index
      }
    }
    
    //console.log(i)
    //console.log(elementToTake)
    //console.log(daysToTake)
    pdfFile.getPage(i).then(function(page) {
      const totaleOre={val:null};
      if(elementToTake||elementToTake===daysToTake)
      page.getTextContent().then(function(textContent) {
        for(let j = 0; j < textContent.items.length; j++) {
          const item=textContent.items[j];
          if(typeof item !== "undefined")
            Object.keys(schema).forEach((key) => { 
              if(extractor.schema[key]!==null&&typeof extractor.schema[key]!== "undefined"){            
                const rangeX=extractor.schema[key].item.transform[4]
                -item.transform[4]
                const rangeY=extractor.schema[key].item.transform[5]
                -item.transform[5]
                
                if(rangeX<extractor.schema[key].item.width/2&&rangeY<2&&
                rangeX>extractor.schema[key].item.width/-2&&rangeY>-2)
                {
                  if(key==="mese")
                  {
                    schema[key]=item.str.toString().replace(/\s|[0-9]/g,"").trim()
                    //console.log(item.str.toString())
                  } 
                  else if(key==="anno")
                  {
                    schema[key]=item.str.toString().replace(/\s|[A-z]/g,"")
                    //console.log(item.str.toString())
                  } 
                  else if(key==="codicefiscale")
                  {
                    //console.log(item.str.toString().replace(/\s/g,""))
                    var cf=item.str.toString().replace(/\s/g,"")
                  /*  var matchCf=cf.toUpperCase().match(/[A-Z]{6}d{2}[A-Z]{1}d{2}[A-Z]{1}d{3}[A-Z]{1}/g)
                    console.log(matchCf)*/
                    cf=cf.length>16?cf.substr(0, 16):cf
                    schema[key]=cf
                    var index=getDatabaseIndex(cf,str)
                    if(index>=0)
                    {
                      elementExists.index=index
                    }
                    lastCF.cf=cf
                    //console.log(lastCF,cf)
                    //console.log(index,"indice codice fiscale",cf,str)
                  }
                  else if(key==="mansione")
                  {
                    const cf=schema['codicefiscale']
                    var mansione=item.str.toString()
                    if(mansione.indexOf(cf)>-1)
                    mansione=mansione.replace(cf,"").trim()
                    schema[key]=mansione.replace(/[0-9]/g,"")                    
                  }
                  /*else if(key==="totaleOreDC")
                  {
                    schema[key]=item.str.toString()
                    totaleOre.val=item.str.toString()
                  }*/
                  else if(key==="nome"||key==="cognome")
                  {
                    const val=item.str.toString()
                    if(val!==null&&val.replace(/[0-9]/g,"")!==""&&val.replace(/[0-9]/g,"")!==" ")
                    schema[key]=val
                  }
                  else {                    
                    schema[key]=item.str.toString()
                  }
                }
              }
          });
        }
      })  

      
      if(daysToTake||elementToTake===daysToTake)
      page.getTextContent().then(function(textContent) {
        //console.log(elementExists.index)
        schema["mesi"]=(elementExists.index>=0
          &&database[elementExists.index]?.mesi!==null
          &&typeof database[elementExists.index]?.mesi !== "undefined")
          ?database[elementExists.index].mesi:
          {GENNAIO:null,FEBBRAIO:null,MARZO:null,APRILE:null,MAGGIO:null,GIUGNO:null
          ,LUGLIO:null,AGOSTO:null,SETTEMBRE:null,OTTOBRE:null,NOVEMBRE:null,DICEMBRE:null}
        
        if(schema?.anno!==null)
          schema.anno=extractor.trovaAnno(schema.anno.replace(/\s/g,""))
        if(schema?.codicefiscale!==null)
          schema.codicefiscale=schema.codicefiscale.includes(' ')?schema.codicefiscale.replace(' ',''):schema.codicefiscale
        if(schema?.mese!==null){            
          schema.mese=extractor.trovaMese(schema.mese.replace(/\s/g,""))
          //console.log(schema.mese)
          const schemaMesi=(elementExists.index>=0&&str[elementExists.index].mesi[schema.mese.toUpperCase()]!==null)?str[elementExists.index].mesi[schema.mese.toUpperCase()]:{days:[],hours:[],totaleOreDC:totaleOre.val}
          if(schema["mesi"][schema.mese.toUpperCase()]===null)
          schema["mesi"][schema.mese.toUpperCase()]=schemaMesi
          var schemaMeseDays=schemaMesi["days"]
          var schemaMeseHours=schemaMesi["hours"]
          for(let j = 0; j < textContent.items.length; j++) {
            const item_=textContent.items[j]
            const itemX=item_.transform[4]
            const itemY=item_.transform[5]
            const daysStartY=Days.start.y
            const daysEndY=Days.end.y
            const daysStartX=Days.start.x
            const daysEndX=Days.end.x
            const direzioneDays=(daysEndX-daysStartX)>(daysEndY-daysStartY)?"o":"v"
            
            
            const hoursStartY=Hours.start.y
            const hoursEndY=Hours.end.y
            const hoursStartX=Hours.start.x
            const hoursEndX=Hours.end.x
            const direzioneHours=(hoursEndX-hoursStartX)>(hoursEndY-hoursStartY)?"o":"v"

            if(item_.str.toString().replace(/\s/g,"")!=="")
            {
              if((itemY >= daysStartY) && (itemY <= daysEndY) &&
              (itemX >= daysStartX) && (itemX <= daysEndX) && item_.str.match(/[0-9]{1}|[0-9]{2}/g)!==null)
              {
                if(direzioneDays==="v")
                schemaMeseDays.push({str:item_.str,itemX:itemX,itemY:itemY+item_.height/2,direction:direzioneDays,height:item_.height,uuid:uuidv4()})
                else if(direzioneDays==="o")
                schemaMeseDays.push({str:item_.str,itemX:itemX+item_.width/2,itemY:itemY,direction:direzioneDays,width:item_.width,uuid:uuidv4()})
              }

              if((itemY >= hoursStartY) && (itemY <= hoursEndY) &&
              (itemX >= hoursStartX) && (itemX <= hoursEndX))
              {
                if(direzioneHours==="v")
                schemaMeseHours.push({str:item_.str,itemX:itemX,itemY:itemY+item_.height/2,direction:direzioneHours,height:item_.height,uuid:uuidv4()})
                else if(direzioneHours==="o")
                schemaMeseHours.push({str:item_.str,itemX:itemX+item_.width/2,itemY:itemY,direction:direzioneHours,width:item_.width,uuid:uuidv4()})
              }              
            }
          }
        }
        
      })    
    }) 

    if(elementExists.index<0){
      //if(schema?.codicefiscale!==null)
      str.push(schema)
    }
    else str[elementExists.index].mesi=schema.mesi    

    if(!persRange)
      setPercLoad(`${parseInt((i*r/(pdfFile.numPages*5))*100)}%`)
    else
    setPercLoad(`${parseInt((i/(pdfFile.numPages))*100)}%`)
    if(i===pdfFile.numPages&&(persRange||r===5)){
      setTimeout(()=>{   
        setDatabases(prev=>{
          const dbs=[...prev]
          dbs.push(extractor.getcompletedSchema(str))
          return dbs
        })   
        if(persRange||r===5)
        {
          setLoading(false) 
          setPercLoad("0%")
          NotificationManager.success('Elaborazione avvenuta con successo')
        }
        //setDatabase(extractor.getcompletedSchema(str))
        
      },1000)
    }
  },500*i*(persRange?1:r),i,r)
  }
  }
 
}
var nextPage=()=>{
  if(isFileSelected){
    if(pdfPage<pdfFile.numPages)
    {
      currentPage=pdfPage+1
      setPDFPage(currentPage)
      openFile()
    }
  }
}
var changePage=(e)=>{
  const page=parseInt(e.target.value)
  if(isFileSelected){
    if(page<pdfFile.numPages&&page>=1)
    {
      currentPage=page
      setPDFPage(currentPage)
      openFile()
    }
  }
}
var previousPage=()=>{  
  if(isFileSelected){    
    if(pdfPage>1)
    {
      currentPage=pdfPage-1
      setPDFPage(currentPage)      
      openFile()
    }
  }  
}
var resetPageBrowser=()=>{
  if(isFileSelected)
  setPageBrowser({display:'block'})
}
var zoomIn=()=>{
  if(isFileSelected)
  {
    setPdfScreenScale(parseFloat(((pdfScreenScale+0.1).toFixed(2))))
    setPDFPage(currentPage)
    setTimeout(()=>{openFile();resetPageBrowser();}, 500)    
  }
}
var zoomOut=()=>{
  if(isFileSelected)
  {
    setPdfScreenScale(parseFloat(((pdfScreenScale-0.1).toFixed(2))))
    setPDFPage(currentPage)
    setTimeout(()=>{openFile();resetPageBrowser();}, 500)    
  }
}
var downloadDatabase=()=> {
  var index=0
  var lastSize=0
  databases.forEach((db,i)=>{
    var size=new Blob([db]).size
    if(size>lastSize)
    {
      lastSize=size
      index=i
    }
  })
  if(databases[index].length>0)
    {
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(databases[index])], {type: 'json'});
      a.href = URL.createObjectURL(file);
      a.download = (new Date()).getTime()+'_database.json';
      a.click();
      NotificationManager.success("Database scaricato con successo!")
    }
    else NotificationManager.warning('Il Database è vuoto!')
  /*if(database.length>0)
  {
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(database)], {type: 'json'});
    a.href = URL.createObjectURL(file);
    a.download = (new Date()).getTime()+'_database.json';
    a.click();
    NotificationManager.success("Database scaricato con successo!")
  }
  else NotificationManager.warning('Il Database è vuoto!')*/
}
//extractor.separa_da_cognome("CACCAMO GIUSY MICHELANGELA","CCCGYM76P69C351R")
  return (
    <div>   
      {loading && (<div className='loader-bg'><span className="loader-perc">{percLoad}</span><i className='bx bx-loader-circle loader'></i></div>   )}
      <NotificationContainer/>
      <h1>Leggi BP PDF</h1> 
      <div style={{position:"absolute", right:"5px", top:"5px"}}>
        <FileUploader hoverTitle="Trascina qui" label="Clicca o Trascina un Database precompilato" handleChange={readJson} name="file" types={fileTypesDatabase} />
      </div>
      <hr />
      <div>
        <div className='block-inline'>
          <FileUploader hoverTitle="Trascina qui" label="Clicca o Trascina qui un PDF" handleChange={readPDFfromFile} name="file" types={fileTypes} />
        </div>
        <div className='block-inline'>
          <button className='btn' onClick={()=>{ setPDFPage(1); setTimeout(()=>{resetCampi();openFile();}, 500);resetPageBrowser()}} >Apri PDF</button>
          <button className='btn' onClick={elabora_buste_paghe} >Elabora Buste Paga</button>
          <button className='btn' onClick={downloadDatabase} >Salva Database</button>
        </div>
        <div style={pageBrowser}>
          <button className='btn' onClick={cambiaOrientamento}>Cambia Orientamento</button>
          <button className='btn' onClick={previousPage} >Precedente</button>    
          <input type="number" style={{height: "28px",textAlign: "center",fontSize: "16px",width: "44px"}} onChange={changePage} value={pdfPage} />
          <button className='btn' onClick={nextPage} >Prossima</button>
          <span> Zoom: </span>
          <button className='btn' onClick={zoomOut} >-</button> 
          <span>{pdfScreenScale}</span>   
          <button className='btn' onClick={zoomIn} >+</button>
          <label for="persRange" style={{display: 'inline-flex', position: 'relative', top: 5.5, marginRight: 10}}>
          <input type="checkbox" id="persRange" style={{height: "20px",width: "20px",marginRight:5}} checked={persRange} onChange={()=>setPersRange(!persRange)} /> Personalizza Raggio</label>   
          {persRange && (<><span> Raggio Verticale: </span>
          <input type="number" style={{height: "28px",textAlign: "center",fontSize: "16px",width: "44px"}} onChange={(event)=>{setRangeYFE(event.target.value);extractor.setRangeFE(event.target.value)}} value={rangeYforExtraction} /></>)}
          
        </div>
      </div>
      <div style={{position:'relative',width:pdfScreenWidth,height:pdfScreenHeight}}>
        {pdfItems}
        <canvas onContextMenu={openContextMenu.bind(this,null)} width={pdfScreenWidth} height={pdfScreenHeight} style={{position:'absolute',zIndex:1}} id="pdf"></canvas>
      </div>
      {contextMenu}
    </div>
  );
}

export default Datafinder;
